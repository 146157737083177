<template>
  <div>
    <rotate-text>PROJECTS</rotate-text>
    <div class="intro">
      <div class="row align-items-center justify-content-end h-100">
        <div class="col-md-10">
          <div class="home-intro">
            <h2 data-aos="fade-up">
              <span>Projects</span>
            </h2>
            <div
              v-show="!sync"
              class="my-4"
              data-aos="fade-up"
              data-aos-delay="250"
            >Loading contents</div>
            <div
              v-show="sync"
              class="my-4"
              data-aos="fade-up"
              data-aos-delay="250"
            >Here are some of my projects</div>
            <div class="arrow-down" data-aos="fade-up" data-aos-delay="500">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path
                  d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="showcase-wrapper">
          <div class="row">
            <div class="col-md-6 col-sm-6" v-for="work in projects" :key="work.id">
              <figure class="showcase-item">
                <router-link :to="{ name: 'projects.show', params: { slug: work.slug } }">
                  <a>
                    <img
                      class="img-fluid"
                      v-lazy="
                        `${appConfig.api.thumbnails_path}/${work.preview}`
                      "
                      alt="0#0"
                    />
                    <figcaption>{{ work.title }}</figcaption>
                  </a>
                </router-link>
              </figure>
              <!-- /showcase-item -->
            </div>
          </div>
        </div>
        <next-page path="/articles" title="Articles" content="Checkout My Shelf"></next-page>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Projects",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content:
            "Hello I am Benjamin Iduwe, here are some of my projects i have worked on",
        },
        {
          name: "og:title",
          content:
            "Hello I am Benjamin Iduwe, here are some of my projects i have worked on",
        },
      ],
    };
  },
  computed: {
    title() {
      return `Projects - ${this.appConfig.name}`;
    },
    ...mapState(["projects", "sync", "appConfig"]),
  },
};
</script>
